
function Competences() {
    return (
      <article className="col">
       <h2>
       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16"><path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path><path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path><path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path></svg>
       Compétences</h2>
      <div className="card">

       <div className="card-body">
       
      

                    <ul>
                        <li>Aménagement paysager</li> 
                        <li>Graphisme, 3D</li>
                       <li>Conception d'applications ( UI-UX Design ) ,</li>
                       <li>Développement d'applications ( BACK-END, FRONT-END ) ,</li>
                        <li>Méthode Agile, Scrum,</li>
                        <li>Veille technologique.</li>
                       
       
       </ul>

       </div>
       </div>
      
       
       </article>
    );
  }
  
  export default Competences;
  