
import './assets/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/theme.css'; 

import "./assets/js/bootstrap.js";

import Header from './components/structure/Header';
import Content from './components/structure/Content';
import Footer from './components/structure/Footer';

function App() {
  return (
    <div className="App">
  
     <Header></Header>
     <Content></Content>
     <Footer></Footer>     
    </div>
  );
}

export default App;
