function Profil() {
    return (
        <section id="profil" className="container-fluid  ">
            <article>  <p>CV</p>
                <h1>Camille BINET</h1>
        
       
        </article>
        </section>
    );
  }
  
  export default Profil;
  