function Outils() {
    return (
      <article className="col outils ">
       
      <h2>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cursor-text" viewBox="0 0 16 16"><path d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.165 4.165 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.49 3.49 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.165 4.165 0 0 1-2.06-.566A4.561 4.561 0 0 1 8 13.65a4.561 4.561 0 0 1-.44.285 4.165 4.165 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.49 3.49 0 0 0-.436-.294A3.166 3.166 0 0 0 5.5 2.5.5.5 0 0 1 5 2zm3.352 1.355zm-.704 9.29z"></path></svg>
       Outils</h2>
       
       <p><span className="badge bg-secondary">JAVA</span>
       <span className="badge bg-secondary">SQL</span>
       <span className="badge bg-secondary">HTML5</span>
       <span className="badge bg-secondary">CSS3</span>
       <span className="badge bg-secondary">SCSS</span>
       </p>
       
       <p><span className="badge bg-secondary">ECLIPSE</span>
                <span className="badge bg-secondary">POSTGRESQL</span>
                <span className="badge bg-secondary">OVH MANAGER</span>
       </p>
       
       <p><span className="badge bg-secondary">VISUAL STUDIO</span>
                <span className="badge bg-secondary">GIT</span>
                <span className="badge bg-secondary">FILEZILLA</span>
                <span className="badge bg-secondary">OBS STUDIO</span>

        </p>

       
       <p><span className="badge bg-secondary">REACT</span>
       <span className="badge bg-secondary">BOOTSTRAP</span>
       <span className="badge bg-secondary">FIGMA</span>
                <span className="badge bg-secondary">ADOBE</span>
                <span className="badge bg-secondary">INKSCAPE</span>
                <span className="badge bg-secondary">3DSMAX </span>
                <span className="badge bg-secondary">BLENDER </span>
       </p>
        

       </article>
    );
  }
  
  export default Outils;
  