function Experiences() {
    return (
        <section id="experiences" className="container-fluid">
           <div className="row  row-cols-sm-1 row-cols-xs-1 row-cols-lg-2">
            
           <h2>
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-briefcase" viewBox="0 0 16 16">
           <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"></path></svg>
            Expériences</h2>

                <article className="col">
                    <div className="card">
                        <div className="card-body">

                            <p> <span className="badge bg-primary"><time>2025 </time></span></p>
                            <h3><a href="https://eden62.fr/" target="_blank" rel="noreferrer">EDEN 62</a></h3>
                            <small>Syndicat mixte</small>

                            <p> Agent D'Amémagement Paysager en Espaces Naturels Sensibles (ENS)</p>
                            <p>CDD</p>
                        </div>
                    </div>
                </article>

                <article className="col">
                    <div className="card">
                        <div className="card-body">

                            <p> <span className="badge bg-primary"><time>2021 </time></span></p>
                            <h3><a href="https://insy2s.com/" target="_blank" rel="noreferrer">INSY2S</a></h3>
                            <small>ESN</small>

                            <p> Conception / Développement d'applications</p>
                            <p>Stage</p>
                        </div>
                    </div>
                </article>


                <article className="col">
                    <div className="card">
                        <div className="card-body">
                            <p> <span className="badge bg-primary"><time>2017</time></span></p>
                            <h3><a href="https://camille-binet.com" target="_blank" rel="noreferrer">Camille Binet Webdesign</a></h3>
                            <small>Micro-entrepreneur</small>
                            <p>Création de site web et gestion de contenus.</p>
                            <p>Freelance</p>
                        </div>
                    </div>
                </article>



            <article  className="col ">
            <div className="card">
            <div className="card-body">
            <p> <span className="badge bg-primary"> <time>2012</time></span></p>
            <h3><a href="https://www.axecibles.com/" target="_blank" rel="noreferrer">AXECIBLES</a></h3>
            <small>Agence web</small><p> UI UX Designer / Développeur</p>
            <p>CDI</p>
            </div>
            </div>
            </article>
            
          
           

           
               

            </div>
            </section>
    );
  }
  
  export default Experiences;
  