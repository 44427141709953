function Formations() {
    return (
        <section className="container-fluid">
        <div className=" row">

        <article id="formations" className="col ">

        <h2>
           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-award" viewBox="0 0 16 16">
        <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z"></path>
        <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"></path>

        </svg> 
        
        FORMATIONS</h2>
        <ul><li><span className="badge bg-primary">2021</span> - <a href="https://www.afpa.fr/" target="_blank" rel="noreferrer">AFPA </a>: Conception, développement d'application</li>
        <li><span className="badge bg-primary">2011</span> - <a href="https://rubika-edu.com/" target="_blank" rel="noreferrer">RUBIKA Valenciennes</a> : Cycle Supérieur 3ème Année / Réalisateur 3D</li>
        <li><span className="badge bg-primary">2010</span> - <a href="https://www.afpa.fr/" target="_blank" rel="noreferrer">AFPA</a> : Formation Infographiste multimédia</li><li><span className="badge bg-primary">2008</span> - <a href="http://stluc-sup-tournai.be/portfolio/archi-interieure/" target="_blank" rel="noreferrer">Ecole supérieur des Arts SAINT LUC</a> : Architecture d’intérieur </li>
        <li><span className="badge bg-primary">2007</span> - <a href="https://alexandre-ribot.fr/" target="_blank" rel="noreferrer">Lycée Alexandre Ribot, SAINT-OMER</a> : Bac STG Marketing</li>
        </ul>
        
        </article>
        
        </div>
        </section>
    );
  }
  
  export default Formations;
  