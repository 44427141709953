import Photo from "./Photo"; 

import ListeImages from "./ListeImages";

 
 




function Book(   ) {
    return (

        <section id="book">

        
           
            <div className="album py-5 bg-body-tertiary">
                <div className="container">

                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                         
                        {/*<Photo data={ListeImages[0]}></Photo>*/}
                        {/*<Photo data={ListeImages[1]}></Photo>*/}
                        {/*<Photo data={ListeImages[2]}></Photo>*/}
                        {/*<Photo data={ListeImages[3]}></Photo>*/}
                        {/*<Photo data={ListeImages[4]}></Photo>*/}
                        {/*<Photo data={ListeImages[5]}></Photo>*/}
                        {/*<Photo data={ListeImages[6]}></Photo>*/}
                        {/*<Photo data={ListeImages[7]}></Photo>*/}
                        {/*<Photo data={ListeImages[8]}></Photo>*/}
                        {/*<Photo data={ListeImages[9]}></Photo>*/}
                        {/*<Photo data={ListeImages[10]}></Photo>*/}
                        {/*<Photo data={ListeImages[11]}></Photo>*/}
                        {/*<Photo data={ListeImages[12]}></Photo>*/}
                        {/*<Photo data={ListeImages[13]}></Photo>*/}
                        {/*<Photo data={ListeImages[14]}></Photo>*/}
                        {/*<Photo data={ListeImages[15]}></Photo>*/}

                       
                         
                        
                    </div>
                </div>
            </div>


            <div class="sketchfab-embed-wrapper"> <iframe title="glitch8" width="100%" height ="400" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/da26eaacce914f71a1368222f6f499dd/embed?autostart=1&transparent=1&ui_theme=dark&dnt=1">
            </iframe> </div>
           
           
        </section>
    );
}

export default Book;
