
import Competences from "../component/Competences";
import Experiences from "../component/Experiences";
import Formations from "../component/Formations";
import Liens from "../component/Liens";
import Outils from "../component/Outils";
import Profil from "../component/Profil";
import Book from "../component/Book";
 
function Content() {
    return (
       <main>  

        
         <section id="cv" className="container-fluid">
        <Profil></Profil>

         </section>

 
         
         
         <section id="competences" className="container-fluid">  
         <div className="row  row-cols-sm-1 row-cols-xs-1 row-cols-lg-2">
        
         <Competences></Competences> 
                    <Outils></Outils>
                    
           
           </div>
           </section>


           <Experiences></Experiences>

            <Formations></Formations>

            <Book></Book>
           
            <Liens></Liens>
         
       </main>
    );
  }
  
  export default Content;
  